import React from 'react';
import Helmet from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';
import Layout from '../components/layout';
import Banner from '../components/Banner';
import Gallery from '../components/Gallery';
import flagsPoster from '../assets/images/separateImages/VELIAVELES_VELGAMA.jpg';
import SinglePicture from '../components/SinglePicture';

const Flags = () => (
  <Layout>
    <Helmet
      title="Velgama - Vėliavos. Baneriai"
      meta={[
        { name: 'description', content: 'Valstybinės ir su Jūsų logo' },
      ]}
    />

    <div id="main">
      <Banner h1="Vėliavos. Baneriai" paragraph="Valstybinės ir su Jūsų logo" bannerType="flags-banner" />
      <section id="one" className="spotlights">
        <StaticQuery
          query={galleryQuery}
          render={(data) => <Gallery data={data} />}
        />
        <SinglePicture
          alt="Plakatas su informacija apie gaminamas vėliavas"
          src={flagsPoster}
        />
      </section>
    </div>
  </Layout>
);

const galleryQuery = graphql`
query flagsImages {
    allFile(
      filter: { sourceInstanceName: { eq: "images-flags" } },
      sort: {order: ASC, fields: name}
      ) {
      edges {
        node {
          dir
          name
          childImageSharp{
            fluid (maxWidth: 1200, maxHeight: 1000){
                ...GatsbyImageSharpFluid
              }
          }
        }
      }
    }
  }
`;

export default Flags;
